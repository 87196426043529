import axios from 'axios';

import { z } from 'zod';

import { Http } from '@/helpers/http';

import { UserCreateSchema } from '@/schemas/user-create.schema';

const url = `${import.meta.env.VITE_API_URL}/auth`;

export const SamleikinService = Object.freeze({
    async checkUser(accessToken: string) {
        return await Http.get<{ user_exists: boolean }>(`${url}/check-samleikin-user-is-verified`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    },

    async checkSamleikinUserEmail(accessToken: string, email: string) {
        return await Http.post<{ user_exists: boolean }>(
            `${url}/check-samleikin-user-email`,
            { email },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
    },

    async verifySamleikinLinking(accessToken: string, email: string, code: string) {
        return await Http.post<{ user_exists: boolean }>(
            `${url}/verify-samleikin-linking`,
            { email, code },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
    },

    async registerUser(data: z.infer<typeof UserCreateSchema>, token: string) {
        return await Http.post(`${url}/register-samleikin-user`, data, { headers: { Authorization: `Bearer ${token}` } });
    },

    async logout() {
        const refreshToken = $auth.keycloak?.refreshToken;
        const token = $auth.keycloak?.token;

        await axios.post(
            `${import.meta.env.VITE_KEYCLOAK_URL}/realms/${import.meta.env.VITE_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
            {
                client_id: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
                refresh_token: refreshToken,
            },
            { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
        );

        await axios.post(
            `${import.meta.env.VITE_KEYCLOAK_URL}/realms/${import.meta.env.VITE_KEYCLOAK_REALM}/protocol/openid-connect/revoke`,
            {
                client_id: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
                token: token,
            },
            { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
        );
    },
});
