<script setup lang="ts">
import { ref } from 'vue';

import VButton from '@/components/VButton.vue';
import VInput from '@/components/VInput.vue';
import VModal from '@/components/modals/VModal.vue';

// Props

defineProps<{
    active: boolean;
    title?: string;
    text?: string;
    callback?: (_input: string) => any;
    buttonText?: string;
    buttonType?: 'default' | 'secondary' | 'danger';
    cancelButtonText?: string;
    cancelCallback?: () => void;
    loading?: boolean;
}>();

// Emits

defineEmits<{
    close: [];
}>();

// Data

const value = ref('');
</script>

<template>
    <VModal
        :active="active"
        focus
        :title="title || ''"
        class="!max-w-3xl"
        @close="$emit('close')"
    >
        <div class="px-4 py-8 lg:px-8">
            <p
                class="mb-4"
                v-html="text"
            />

            <VInput
                v-model="value"
                @enter="
                    callback?.(value);
                    $emit('close');
                "
            />
        </div>

        <template #footer>
            <div class="flex w-full flex-col  gap-3 md:flex-row-reverse">
                <VButton
                    :type="buttonType || 'default'"
                    :text="buttonText || $t('Í lagi')"
                    :loading="loading"
                    @click="
                        callback?.(value);
                        $emit('close');
                    "
                />

                <VButton
                    type="secondary"
                    :text="cancelButtonText || $t('Angra')"
                    @click="
                        cancelCallback?.();
                        $emit('close');
                    "
                />
            </div>
        </template>
    </VModal>
</template>
