<script setup lang="ts">
import { ref } from 'vue';

import { AuthService } from '@/services/auth.service';

import VButton from '@/components/VButton.vue';
import VModal from '@/components/modals/VModal.vue';

// Props

defineProps<{
    active: boolean;
}>();

// Emits

const emit = defineEmits<{
    close: [];
}>();

// Data

const loading = ref(false);
const logoutLoading = ref(false);

// Function

async function refreshToken() {
    if (loading.value || logoutLoading.value) {
        return;
    }

    loading.value = true;

    await $auth.keycloak?.updateToken(-1);

    loading.value = false;

    emit('close');
}

async function signOut() {
    if (loading.value || logoutLoading.value) {
        return;
    }

    logoutLoading.value = true;

    await AuthService.logout();

    emit('close');
}
</script>

<template>
    <VModal
        :active="active"
        locked
        :title="$t('Tú verður rita/ur út um 60 sekund.')"
        class="!max-w-2xl"
    >
        <div class="flex flex-col gap-6 px-4 py-8 lg:px-8">
            <p>
                {{ $t('Um tú vil halda fram at vera innrita/ur, trýst á endurnýggja innritan knøttin.') }}
            </p>
        </div>

        <template #footer>
            <div class="flex w-full flex-col gap-3 md:flex-row-reverse">
                <VButton
                    :text="$t('Endurnýggja innritan')"
                    :loading="loading"
                    @click="refreshToken"
                />

                <VButton
                    type="secondary"
                    :text="$t('Rita út')"
                    :loading="logoutLoading"
                    @click="signOut"
                />
            </div>
        </template>
    </VModal>
</template>
